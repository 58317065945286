import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";
import { createUpdateUrlParam } from '../utils/create_update_url_param';
import ApiService from "../services/api_service";

export const usePdqStore = defineStore('pdq', () => {
    const lang = ref({})
    const step = ref(1)
    const leakTypes = ref({})
    const sympthoms = ref({})
    const healthWorkerTypes = ref({})

    const formData = ref({
        had_leak: null,
        leak_type: null,
        sympthom: null,
        health_worker_took_sample: null,
        health_worker_types: [],
        other_health_worker_desc: null,
        sympthoms_date: null,
        prescribed_antibiotics: null,
        who_prescribed_antibiotics: null,
        re_admitted_to_hospital: null,
        re_admitted_to_same_hospital: null,
        hospital_desc: null,
        note: null
    })

    const nextStep = () => {
        step.value++
    }

    const prevStep = () => {
        if (step.value == 1) {
            createUpdateUrlParam('exit', true)
            return;
        }
        step.value--
    }

    const submit = (id, token) => {
        return new Promise((resolve, reject) => {
            formData.value.had_problems = true
            ApiService.put('/pdq/' + id, { ...formData.value, token: token }).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    return {
        lang,
        step,
        leakTypes,
        sympthoms,
        healthWorkerTypes,
        formData,
        nextStep,
        prevStep,
        submit
    }
})