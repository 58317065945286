<template>
    <div>
        <div class="fs-24px fw-bold text-dark-blue mb-5"
            v-html="langStore.lang.additional_sympthoms_question_html + ':'">
        </div>
        <div>
            <button class="btn btn-white rio-pdq-button w-100 text-start ps-4 mb-3"
                v-for="(sympthom, key) in store.sympthoms" :key="key"
                :class="{ 'active': store.formData.sympthom == key }" @click="selectSymptom(key)"
                v-html="langStore.lang.sympthoms[key + '_html']">
            </button>
        </div>
    </div>
</template>
<script setup>
import { usePdqStore } from '../../stores/pdq';
import { useLangStore } from '../../stores/lang';

const store = usePdqStore();
const langStore = useLangStore();

const selectSymptom = (value) => {
    store.formData.sympthom = value
    store.nextStep();
}
</script>